/**
 * This is some of module federation magic.
 * We need to load the complete app asynchronously to
 * make sure all the remote modules are registered and available.
 * not doing this can lead to following errors
 * 1. __webpack_modules__[id] is not a function
 * 2. <federated_module_name> modules not found.
 * This is for client side and similar is done on server side
 */

// eslint-disable-next-line no-unused-expressions
import('./client');
